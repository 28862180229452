import React from "react";
import { Stack, Wrap, WrapItem, Heading } from "@chakra-ui/react";

import Layout from "../../../components/Layout";
import Profile from "../../../ui/common/Profile";

const imageDir = "/static/images";

const team = [
  {
    photo: "sternberg-paul.png",
    name: "Paul Sternberg",
    position: "Editor-In-Chief",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "schedl-tim.png",
    name: "Tim Schedl",
    position: "Science Officer",
    species: "C elegans",
    affiliation: "Washington University School of Medicine, St Louis, MO USA",
  },
  {
    photo: "raciti-daniela.png",
    name: "Daniela Raciti",
    position: "Executive Editor",
    species: "",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "yook-karen.png",
    name: "Karen Yook",
    position: "Executive Editor",
    species: "",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    // photo: "harris-todd.png",
    name: "Todd Harris",
    position: "Technology and Operations Manager",
    affiliation: "Ontario Institute for Cancer Research, Toronto Canada",
  },
  {
    photo: "nick.jpg",
    name: "Nicholas Stiffler",
    position: "Core Developer",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "cecilia-nakamura.jpg",
    name: "Cecilia Nakamura",
    position: "Billing",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "claire-wright-coleman.jpg",
    name: "Claire Wright-Coleman",
    position: "Administrative Assistant",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
];

const staff = [
  {
    photo: "kaufman-tom.jpg",
    name: "Thom Kaufman",
    position: "Science Officer",
    species: "D. melanogaster",
    affiliation: "Indiana University, Bloomington, IN USA",
  },
  {
    photo: "brian_oliver.jpg",
    name: "Brian Oliver",
    position: "Science Officer",
    species: "D. melanogaster",
    affiliation:
      "National Institute of Diabetes and Digestive and Kidney Diseases Bethesda, MD USA",
  },
  {
    photo: "kami-ahmad.jpg",
    name: "Kami Ahmad",
    position: "Science Officer",
    species: "D. melanogaster",
    affiliation: "Fred Hutchinson Cancer Research Center, Seattle, WA, USA",
  },
  {
    photo: "Steven.jpg",
    name: "Steven Marygold",
    position: "Managing Editor",
    species: "D. melanogaster",
    affiliation: "University of Cambridge, Cambridge, UK",
  },
  {
    photo: "yang-jing.jpg",
    name: "Jing Yang",
    position: "Science Officer",
    species: "Xenopus",
    affiliation: "University of Illinois at Urbana-Champaign, Urbana, IL USA",
  },
  {
    photo: "james-zorn_christina-.jpg",
    name: "Christina James-Zorn",
    position: "Managing Editor",
    species: "Xenopus",
    affiliation: "Cincinnati Childrens, Cincinnati, OH USA",
  },
  {
    photo: "jae-cho.jpg",
    name: "Jaehyoung Cho",
    position: "Managing Editor",
    species: "C. elegans",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "gary-s.jpg",
    name: "Gary Schindelman",
    position: "Managing Editor",
    species: "C. elegans",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "cathy-savage-dunn.jpg",
    name: "Cathy Savage-Dunn",
    position: "Science Officer",
    species: "C. elegans",
    affiliation:
      "Queens College at the City University of New York, Flushing, NY, USA",
  },
  {
    photo: "yishi-jin.jpg",
    name: "Yishi Jin",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "University of California, San Diego, CA USA",
  },
  {
    photo: "cheryl-van-buskirk.jpg",
    name: "Cheryl Van Buskirk",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "California State University, Northridge, CA, USA",
  },
  {
    photo: "lina-dahlberg.jpeg",
    name: "Lina Dahlberg",
    position: "Science Officer",
    species: "C. elegans, undergraduate research experience",
    affiliation: "Western Washington University, Bellingham, WA, USA",
  },
  {
    photo: "matt-marcello.jpg",
    name: "Matt Marcello",
    position: "Science Officer",
    species: "C. elegans, undergraduate research experience",
    affiliation: "PACE University, New York, NY, USA",
  },
  {
    photo: "christian-froekjaer.png",
    name: "Christian Froekjaer Jensen",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "KAUST, Saudi Arabia",
  },
  {
    photo: "alon-zaslaver.jpg",
    name: "Alon Zaslaver",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "Hebrew University of Jerusalem, Jerusalem, Israel",
  },
  {
    photo: "yen-ping-hsueh.png",
    name: "Yen-Ping Hsueh",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "Academia Sinica, Taipei, Taiwan",
  },
  {
    photo: "chiou-fen-chuang.jpg",
    name: "Chiou-Fen Chuang",
    position: "Science Officer",
    species: "C. elegans, Fungi",
    affiliation: "University of Illinois at Chicago, Chicago, Illinois, USA",
  },
  {
    photo: "bhagwati-gupta.jpg",
    name: "Bhagwati Gupta",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "McMaster University Hamilton, Ontario, Canada",
  },
  {
    photo: "david-reiner.jpg",
    name: "David Reiner",
    position: "Science Officer",
    species: "C. elegans",
    affiliation: "Texas A&M Health Science Center, Houston, TX, USA",
  },
  {
    photo: "leonore-reiser.png",
    name: "Leonore Reiser",
    position: "Managing Editor",
    species: "Arabidopsis",
    affiliation: "Phoenix Bioinformatics, Fremont, CA USA",
  },
  {
    photo: "Iai_Searle.png",
    name: "Iain Searle",
    position: "Science Officer",
    species: "Arabidopsis",
    affiliation: "The University of Adelaide, Adelaide Australia",
  },
  {
    photo: "nick-kaplinsky.jpg",
    name: "Nick Kaplinsky",
    position: "Science Officer",
    species: "Arabidopsis",
    affiliation: "Swarthmore College, Swarthmore, PA USA",
  },
  {
    photo: "charles-hoffman.jpg",
    name: "Charles Hoffman",
    position: "Science Officer",
    species: "S. pombe",
    affiliation: "Boston College, Chestnut Hill, MA USA",
  },
  {
    photo: "sarah-lambert.jpg",
    name: "Sarah Lambert",
    species: "S. pombe",
    position: "Science Officer",
    affiliation: "Curie Institute, Paris, France",
  },
  {
    photo: "sarah-sabatinos.jpg",
    name: "Sarah Sabatinos",
    position: "Science Officer",
    species: "S. pombe",
    affiliation: "Ryerson University, Toronto, Ontario, Canada",
  },
  {
    photo: "monte-westerfield.png",
    name: "Monte Westerfield",
    position: "Science Officer",
    species: "Zebrafish",
    affiliation: "University of Oregon, Eugene, OR USA",
  },
  {
    photo: "PetraFey.jpeg",
    name: "Petra Fey",
    position: "Managing Editor",
    species: "Dictyostelium",
    affiliation: " Northwestern University, Chicago, IL USA",
  },
  {
    photo: "Rex_L_Chisholm.jpg",
    name: "Rex Chisholm",
    position: "Science Officer",
    species: "Dictyostelium",
    affiliation: "Northwestern University, Chicago, IL USA",
  },
  {
    photo: "lukas-mueller.jpg",
    name: "Lukas Mueller",
    position: "Science Officer",
    species: "Solanaceae",
    affiliation: "Cornell University, Ithaca, NY, USA",
  },
  {
    photo: "ann-kirchmaier.jpg",
    name: "Ann Kirchmaier",
    position: "Science Officer",
    species: "S. cerevisiae",
    affiliation: "Purdue University, West Lafayette, IN, USA",
  },
  {
    photo: "stacia-engel.jpg",
    name: "Stacia Engel",
    position: "Managing Editor",
    species: "S. cerevisiae",
    affiliation: "Stanford University, Palo Alto, CA USA",
  },
  {
    photo: "mike-law.png",
    name: "Michael Law",
    position: "Science Officer",
    species: "S. cerevisiae",
    affiliation: "Stockton University, Galloway, NJ, USA",
  },
  {
    photo: "carson-andorf.png",
    name: "Carson Andorf",
    position: "Science Officer",
    species: "Maize",
    affiliation: "MaizeGDB, USDA-ARS",
  },
  {
    photo: "joel-sussman.png",
    name: "Joel Sussman",
    position: "Science Officer",
    species: "Structural Biology",
    affiliation: "Weizmann Institute of Science, Rehovot, Israel",
  },
  {
    photo: "jaime-prilusky.png",
    name: "Jaime Prilusky",
    position: "Proteopedia Editor",
    species: "Structural Biology",
    affiliation: "Weizmann Institute of Science, Rehovot, Israel",
  },
  {
    photo: "jacqueline-campbell.jpg",
    name: "Jacqueline Campbell",
    position: "Science Officer",
    species: "Soybean",
    affiliation: "SoyBase, USDA-ARS",
  },
  {
    photo: "evelina-basenko.jpg",
    name: "Evelina Basenko",
    position: "Science Officer",
    species: "Fungi",
    affiliation: "University of Liverpool, Liverpool, England, United Kingdom",
  },
  {
    photo: "ksenija-gasic.jpg",
    name: "Ksenija Gasic",
    position: "Science Officer",
    species: "Rosaceae",
    affiliation: "Clemson University, Clemson, SC, USA",
  },
  {
    photo: "susana-wadgymar.jpg",
    name: "Susana Wadgymar",
    position: "Science Officer",
    species: "Ecology and Evolution",
    affiliation: "Davidson College, Davidson, NC USA",
  },
  {
    photo: "stacy-farina.jpg",
    name: "Stacy Farina",
    position: "Science Officer",
    species: "Ecology and Evolution",
    affiliation: "Howard University, Washington, DC USA",
  },
  {
    photo: "robert-williamson.jpg",
    name: "Robert J Williamson",
    position: "Science Officer",
    species: "Ecology and Evolution",
    affiliation: "Rose-Hulman Institute of Technology, Terre Haute, IN USA",
  },
  {
    photo: "margaret-metz.jpg",
    name: "Margaret Metz",
    position: "Science Officer",
    species: "Ecology and Evolution",
    affiliation: "Lewis & Clark College, Portland, OR USA",
  },
  {
    photo: "santiago-salinas.jpg",
    name: "Santiago Salinas",
    position: "Science Officer",
    species: "Ecology and Evolution",
    affiliation: "Kalamazoo College, Kalamazoo, MI USA",
  },
  {
    photo: "douglas-chalker.jpg",
    name: "Douglas Chalker",
    position: "Science Officer",
    species: "Ciliates",
    affiliation: "Washington University, St Louis, MO USA",
  },
  {
    photo: "paul-craig.jpg",
    name: "Paul Craig",
    position: "Science Officer",
    species: "Computational Biology, Protein structure prediction",
    affiliation: "Rochester Institute of Technology, Rochester, NY, USA",
  },
  {
    photo: "tsui-fen-chou.jpg",
    name: "Tsui-Fen Chou",
    position: "Science Officer",
    species: "Biochemistry",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "jared-leadbetter.jpg",
    name: "Jared Leadbetter",
    position: "Science Officer",
    species: "Microbiology",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
];

const curators = [
  {
    photo: "chris-grove.jpg",
    name: "Chris Grove",
    position: "Curator",
    species: "WormBase",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "stavros-diamantakis.jpg",
    name: "Stavros Diamantakis",
    position: "Curator",
    species: "WormBase",
    affiliation: "EMBL-EBI, Hinxton, Cambridge, England, United Kingdom",
  },
  {
    photo: "raymond-lee.jpg",
    name: "Raymond Lee",
    position: "Curator",
    species: "WormBase",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "ranjana-kishore.jpg",
    name: "Ranjana Kishore",
    position: "Curator",
    species: "WormBase",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "rex-nelson.jpg",
    name: "Rex Nelson",
    position: "Curator",
    species: "Soybase",
    affiliation: "USDA-ARS",
  },
  {
    photo: "naomi-stover.jpg",
    name: "Naomi Stover",
    position: "Curator",
    species: "Tetrahymena Genome Database",
    affiliation: "Bradley University, Peoria, IL USA",
  },
  {
    photo: "valerie-wood.jpg",
    name: "Valerie Wood",
    position: "Curator",
    species: "PomBase",
    affiliation: "University of Cambridge, Cambridge, UK",
  },
  {
    // photo: ".jpg",
    name: "Constance Smith",
    position: "Curator",
    species: "Mouse Genome Informatics",
    affiliation: "The Jackson Laboratory, Bar Harbor, ME USA",
  },
  {
    photo: "jing-yu.jpg",
    name: "Jing Yu",
    position: "Curator",
    species: "CottonGen",
    affiliation: "Washington State University, WA USA",
  },
];

const alumni = [
  {
    photo: "sarah-torres.png",
    name: "Sarah Torres",
    position: "Publishing Editor",
    affiliation: "California Institute of Technology, Pasadena, CA USA",
  },
  {
    photo: "golden-andy.png",
    name: "Andy Golden",
    position: "Science Officer",
    species: "C. elegans",
    affiliation:
      "The National Institute of Diabetes and Digestive and Kidney Diseases, Bethesda, MD USA",
  },
  {
    photo: "lisa-harper.jpg",
    name: "Lisa Harper",
    position: "Managing Editor",
    species: "Maize",
    affiliation: "MaizeGDB, USDA-ARS",
  },
];

const Profiles = () => {
  return (
    <Stack align="center" paddingTop="100px">
      <Heading color="gray">Core Editorial Team</Heading>
      <Wrap maxW="6xl">
        {team.map((person) => (
          <WrapItem key={`team-${person.name}`}>
            <Profile
              {...person}
              photo={person.photo && `${imageDir}/${person.photo}`}
            />
          </WrapItem>
        ))}
      </Wrap>

      <Heading color="gray">Editorial Staff</Heading>
      <Wrap maxW="6xl">
        {staff.map((person) => (
          <WrapItem key={`staff-${person.name}`}>
            <Profile
              {...person}
              photo={person.photo && `${imageDir}/${person.photo}`}
            />
          </WrapItem>
        ))}
      </Wrap>

      <Heading color="gray">Curators</Heading>
      <Wrap maxW="6xl">
        {curators.map((person) => (
          <WrapItem key={`staff-${person.name}`}>
            <Profile
              {...person}
              photo={person.photo && `${imageDir}/${person.photo}`}
            />
          </WrapItem>
        ))}
      </Wrap>

      <Heading color="gray">Alumni</Heading>
      <Wrap maxW="6xl">
        {alumni.map((person) => (
          <WrapItem key={`staff-${person.name}`}>
            <Profile
              {...person}
              photo={person.photo && `${imageDir}/${person.photo}`}
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};

const EditorialStaff = (props) => {
  const { isSection } = props;
  if (isSection) return <Profiles />;
  return <Layout children={<Profiles />} />;
};

export default EditorialStaff;
